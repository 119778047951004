<template>
	<div class="page">
		<!-- 拍摄示范 -->
		<template v-if="isShowDialog" >
			<div class="tips-content">
				<div class="content">
					<!-- <van-icon name="close" color="#000000" class="close-icon" @click="closeTips" /> -->
					<div class="title" :style="{color: style.btnColor}">拍摄示范</div>
					<img :src="style.shootTipsImg" alt="" class="shoot-tips-img">
					<div class="content-text">
						<span>请上传个人名片</span> <br >
						<span>务必横向拍摄，确保边框完整，字体清晰，亮度均匀</span> <br >
						<span>请勿<del>上传身份证、工作证</del>等证件照片</span>
					</div>
				</div>
				<van-button type="primary" block :color="style.btnColor" class="font20 radius btn" style="margin-bottom: 0;" @click="shootStart">开始拍摄</van-button>
			</div>
		</template>
		
		<div v-else class="container">
			<div class="notes">
				<div v-if="errorTips" class="error-tips">{{errorTips}}</div>
				<div v-else>
					<h1>请拍摄您的名片</h1>
					<h4>拍名片，快速上传信息</h4>
				</div>
			</div>
			<div class="card-container">
				<div class="box">
					<!-- 名片正面 -->
					<div class="card">
						<!-- 未选择图片或图片校验未通过时 -->
						<div v-if="!front.isSuccess" class="tips-layer border" :class="{mask: front.isUpload && !front.isSuccess}" :style="{color: style.btnColor}"
						 @click="toShoot('front')">
							<!-- 未选择图片，且未进行图片校验 -->
							<div v-if="!data.aCardPath && !front.isUpload">
								<van-icon name="add-o" :color="style.btnColor" class="add-icon" />
								<span class="tips-text default">点击上传名片正面</span>
							</div>
							<!-- 已进行图片校验，但校验未通过时 -->
							<div v-if="front.isUpload && !front.isSuccess">
								<van-icon name="add-o" class="add-icon text-white" />
								<span class="tips-text text-white">点击重新拍摄</span>
							</div>
						</div>
						<img v-if="data.aCardPath" :src="data.aCardPath" class="fill img border" :style="{color: style.btnColor}" @click="toShoot('front')">
					</div>
					<!-- 校验通过提示icon -->
					<van-icon v-if="front.isSuccess" name="checked" :color="style.btnColor" class="success-icon" />
				</div>
 
				<!-- 名片反面 -->
				<div class="box">
					<div class="card">
						<div v-if="!back.isSuccess" class="tips-layer border" :class="{mask: back.isUpload && !back.isSuccess}" :style="{color: style.btnColor}"
						 @click="toShoot('back')">
						 <!-- 未选择图片，且未进行图片校验 -->
							<div v-if="!data.bCardPath && !back.isUpload">
								<van-icon name="add-o" :color="style.btnColor" class="add-icon" />
								<span class="tips-text default">点击上传名片反面</span>
							</div>
							<!-- 已进行图片校验，但校验未通过时 -->
							<div v-if="back.isUpload && !back.isSuccess">
								<van-icon name="add-o" class="add-icon text-white" />
								<span class="tips-text text-white">点击重新拍摄</span>
							</div>
						</div>
						<img v-if="data.bCardPath" :src="data.bCardPath" class="fill img border" :style="{color: style.btnColor}" @click="toShoot('back')">
					</div>
					<!-- 校验通过提示icon -->
					<van-icon v-if="back.isSuccess" name="checked" :color="style.btnColor" class="success-icon" />
				</div>

			</div>
			<van-button v-if="front.isSuccess && back.isSuccess" type="primary" block :color="style.btnColor" class="font16 radius btn" @click="nextStep">下一步</van-button>
			
			<!-- 手动输入名片信息 -->
			<!-- <van-button v-if="!front.isSuccess" type="primary" block :color="style.btnColor" class="font16 radius btn" @click="fillInfo">手动输入名片信息</van-button> -->
			<div class="text-center">
				<span v-if="!front.isSuccess && style.isAllowSwitch" class="font16 letter-spacing fill-btn" @click="fillInfo">{{linkText}}</span>
			</div>
		</div>

		<input v-show="false" type="file" id="fileElem" accept="image/*" ref="imgFile" @change="chooseImg">
		
		<!-- 页脚 -->
		<div class="footer">
			<bottom :mode="style.mode" :level="style.level"></bottom>
		</div>

	</div>
</template>

<script>

	import commonMixin from '@/view/process/mixin/common.js';
	import shootCardMixin from '@/view/process/mixin/shootCard.js';

	export default {
		mixins: [commonMixin, shootCardMixin],
		data() {
			return {
				
			}
		},
		created() {
			this.setLanguge('zh'); // 设置语言环境
		},
		methods: {

		},
	}
</script>

<style lang="less" scoped>
	@color: #9F9D9D;
	// @color: #6D6D6D;

	.border {
		border: 3px solid;
	}

	.mask {
		background-color: rgba(0, 0, 0, .5);
	}

	.notes {
		margin: 8vh auto 5vh;
		height: 80px;
		color: @color;
		text-align: center;
		
		.error-tips {
			font-size: 24px;
			padding-top: 10px;
		}
		
		h1 {
			font-size: 26px;
			margin-bottom: 3vh;
		}

		h4 {
			font-size: 16px;
		}
	}

	.card-container {
		width: 280px;
		margin: 0 auto 6vh;

		.box {
			position: relative;
			margin-bottom: 4vh;

			.card {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 200px;
				height: 120px;
				background-color: #fff;
				border-radius: 10px;
				margin: 0 auto;
				text-align: center;
				
				.img {
					border-radius: 10px;
				}

				.add-icon {
					font-size: 30px;
					margin-bottom: 2vh;
				}

				.tips-text {
					display: block;
					text-align: center;
					font-size: 16px;
				}

				.default {
					color: #070707;
				}

				.tips-layer {
					position: absolute;
					left: 0;
					top: 0;
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: 100%;
					height: 100%;
					border-radius: 10px;
					box-sizing: border-box;
					z-index: 1;
				}

				.van-button {
					position: absolute;
					width: 100%;
					height: 100%;
					background: transparent;
					border: none;
					z-index: 10;
				}
			}

			.success-icon {
				position: absolute;
				top: 44px;
				right: 0;
				font-size: 24px;
			}
		}
	}

	.btn {
		width: 240px;
		margin: 0 auto 3vh;
	}

	.fill-btn {
		// display: block;
		color: @color;
		text-align: center;
		padding-bottom: 4px;
		border-bottom: 2px solid;
		margin: 0 auto 3vh;
	}

	.tips-content {
		width: 310px;
		margin: 8vh auto 0;

		.content {
			position: relative;
			background-color: #fff;
			padding: 20px 0;
			margin-bottom: 6vh;
			border-radius: 10px;

			// .close-icon {
			// 	position: absolute;
			// 	top: 6px;
			// 	right: 6px;
			// 	font-size: 28px;
			// 	z-index: 10000;
			// }

			.title {
				font-weight: bold;
				font-size: 24px;
				text-align: center;
				letter-spacing: 1Px;
				margin-bottom: 5vh;
			}

			.shoot-tips-img {
				display: block;
				margin: 0 auto 4vh;
				width: 200px;
			}

			.content-text {
				line-height: 26px;
				font-size: 12px;
				color: #000;
				text-align: center;
				font-weight: bold;
			}
		}
	}

</style>
